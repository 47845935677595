@import "src/scss/typography";
@import "src/scss/layout";

.c-related {
	.content-holder {
		@extend .content-holder__padded;
	}

	.c-card {
		opacity: 1;

		transition: all 0.3s;
	}

	&__header {
		@extend .eyebrow;
		margin-bottom: 30px;
	}
	&__items {
		display: flex;
		justify-content: space-between;
		margin-bottom: 40px;
	}
	&__item {
		width: calc(33.3333% - 30px);
		background: black;
		position: relative;
		overflow: hidden;
		&:before {
			width: 100%;
			content: "";
			display: block;
			padding-top: 100%;
		}
	}
	&__photo {
		img {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	&__quote {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		//padding: 20px;
	}

	@media (max-width: 550px) {
		&__items {
			flex-direction: column;
			margin-bottom: 60px;
		}
		.c-card {
			margin-bottom: 15px;
			width: 100% !important;
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
