p {
	font-weight: 300;
	font-size: 18px;
	color: #ffffff;
	letter-spacing: 0;
	line-height: (26/18);

	&.intro {
		font-weight: 300;
		font-size: 32px;
		color: #ffffff;
		letter-spacing: 0;
		text-align: center;
		line-height: (40/34);
		margin-bottom: 60px;
	}
	&.desc {
		font-weight: 300;
		font-size: 18px;
		color: #ffffff;
		letter-spacing: 0;
		line-height: (26/18);
	}

	a {
		color: white;
		text-decoration: underline;
	}
}

ul {
	@extend p;
	list-style-type: square;
	padding-left: 20px;

	li {
		margin-bottom: 10px;
	}
}

strong {
	font-weight: bold;
}

h2 {
	font-family: "Spot", sans-serif;
	font-size: 46px;
	color: #ffffff;
	letter-spacing: 0;
	text-align: center;
	line-height: (50/46);
	margin-bottom: 20px;
}

h3 {
	font-family: "Spot", sans-serif;
	//font-weight: bold;
	font-size: 32px;
	line-height: (38/32);
	color: #ffffff;
	//letter-spacing: 0;
	margin-bottom: 10px;
}

h4 {
	font-family: "Spot", sans-serif;
	font-size: 24px;
	line-height: (26/24);
	margin-bottom: 40px;
}

strong {
	font-weight: bold;
}

.ts-background-text {
	color: transparent;
	background: linear-gradient(90deg, #fd1e46 0%, #fd1e46 20%, #7a10aa 100%);
	background-size: contain;
	background-clip: text;
	-webkit-background-clip: text;
}

.eyebrow {
	font-weight: 700;
	font-size: 24px;
	color: #ffffff;
	letter-spacing: 0;
	line-height: 32px;
}

@media (max-width: 1100px) {
	p {
		font-size: 16px;
		&.intro {
			font-size: 26px;
			margin-bottom: 40px;
		}
	}
	h2 {
		font-size: 36px;
	}
	h3 {
		font-size: 26px;
	}
	h4 {
		font-size: 20px;
		margin-bottom: 27px;
	}
}

@media (max-width: 670px) {
	p {
		font-size: 14px;
		&.intro {
			font-size: 18px;
			margin-bottom: 20px;
		}
	}
	h2 {
		font-size: 28px;
	}
	h3 {
		font-size: 22px;
	}
	h4 {
		font-size: 16px;
		margin-bottom: 15px;
	}
}
